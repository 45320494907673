import { SVGProps, Ref, forwardRef } from 'react'

const SvgInfoCircle = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.25 16.5h-.75A1.5 1.5 0 0 1 12 15v-3.75a.75.75 0 0 0-.75-.75h-.75"
    />
    <path fill="currentColor" d="M12 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.045 19.955a11.25 11.25 0 1 1 15.91-15.91 11.25 11.25 0 0 1-15.91 15.91Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgInfoCircle)
export default ForwardRef
